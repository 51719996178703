<template>
  <div class="mm-mm-text-filter">
    <TextField
      icon-path="none"
      :max-length="internalFilter.maxLength"
      :label="internalFilter.name"
      :mask="internalFilter.mask"
      :model-value="internalFilter.value"
      @enter="onPressEnter()"
      @update:model-value="onUpdateModelValue(String($event))"
    />
  </div>
</template>

<script lang="ts" setup>
import { cloneDeep } from 'lodash-es';
import TextField from '../../components/TextField.vue';
import { IFilterText } from '../../models/filters.model';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  filter: IFilterText;
  modelValue: IFilterText;
}>();

const emit = defineEmits<{
  (e: 'enter'): void;
  (e: 'update:modelValue', newValue: IFilterText | undefined): void;
}>();

const watchSubscription = new WatchSubscription();
const internalFilter = ref<IFilterText>();

function onPressEnter(): void {
  emit('enter');
}

function onUpdateModelValue(value: string): void {
  updateModelValue(value);
}

function updateModelValue(value: string): void {
  if (internalFilter.value) {
    internalFilter.value.value = value;
    emit('update:modelValue', internalFilter.value);
  }
}

function setInternalFilter(filter: IFilterText | undefined): void {
  internalFilter.value = cloneDeep(filter);
}

watchSubscription.add(
  watch(
    () => props.modelValue,
    (value) => setInternalFilter(value || props.filter),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

setInternalFilter(props.modelValue || props.filter);
</script>

<style lang="scss" scoped>
:deep(.mm-input--with-label) {
  padding-bottom: 0;
}

.mm-text-filter {
  display: flex;
  align-items: center;
  width: 365px;
}
</style>
