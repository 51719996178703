<template>
  <Checkbox
    class="filter__list__item"
    :disabled="disabled"
    :model-value="fieldModelValue"
    @update:model-value="onUpdate"
  >
    {{ filter?.name }} <br>
    {{ filter.description }}

    <span
      v-if="showCount"
      class="filter__list__item-count"
    >
      {{ count }}
    </span>
  </Checkbox>
</template>

<script lang="ts" setup>
import Checkbox from 'shared/components/Checkbox.vue';
import { IFilterBoolean } from 'models/filters.model';
import { cloneDeep } from 'lodash-es';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  filter: IFilterBoolean;
  modelValue: IFilterBoolean;
  showCount?: boolean;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', newValue: IFilterBoolean): void;
}>();

const watchSubscription = new WatchSubscription();
const fieldModelValue = ref<boolean>(props.modelValue?.value);
const count = ref<number>(props.filter.count ?? 0);

function onUpdate(event: boolean): void {
  const cloneFilter: IFilterBoolean = cloneDeep<IFilterBoolean>(toRaw(props.modelValue));
  cloneFilter.value = event;
  emits('update:modelValue', cloneFilter);
}

watchSubscription.add(
  watch(
    () => props.modelValue,
    (newModelValue) => fieldModelValue.value = newModelValue?.value,
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
.filter__list__item {
  :deep(span.content),
  :deep(span.light) {
    display: inline-block;

    &::first-line {
      line-height: 24px;
    }
  }
}
</style>
