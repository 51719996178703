<template>
  <div
    :class="{
      'mm-sort-filter': true,
      'mm-sort-filter--with-search': needSearch,
    }"
  >
    <div
      v-if="filter?.name && hideName"
      class="mm-sort-filter__title"
    >
      <span>{{ filter?.name }}</span>
    </div>
    <div v-if="needSearch">
      <TextField
        v-model="searchText"
        :disabled="disabled"
        class="mm-sort-filter__search"
        icon-path="navigation/search"
        placeholder="Поиск"
        size="small"
      />
    </div>

    <div
      class="mm-sort-filter__list"
      :class="{ 'mm-sort-filter__list--scroll': !!scroll }"
    >
      <PerfectScrollbar
        class="scrollbar"
        watch-options
        :options="{ wheelPropagation: true, scrollYMarginOffset: 5 }"
      >
        <RadioGroup
          v-if="radioMode"
          column
          is-disable-emit-value-by-btn-list-change
          :buttons="displayedRadioSelectOptions"
          :disabled="disabled"
          :model-value="selectedId"
          @update:model-value="handleRadioChange"
        />

        <div
          v-else-if="grouped"
          class="mm-select-grouped"
        >
          <div
            v-for="(group, index) in displayedGroupdSelectOptions"
            :key="index"
            class="mm-select-grouped-group"
          >
            <div class="mm-select-grouped-group__title">
              {{ group.name }}
            </div>
            <div class="mm-select-grouped-group__options">
              <div
                v-for="option in (group?.options || [])"
                :key="option.id"
                class="mm-select-grouped-group-option"
                @click="onItemSelect(option.id)"
              >
                <span class="mm-select-grouped-group-option__name">
                  {{ option?.name || '-' }}
                </span>
                <SvgIcon
                  v-if="selectedId === option.id"
                  :src="EIconPath.IndicatorsCheckmarkSimpleSvg"
                  class="mm-select-grouped-group-option__checkmark"
                  :wrapper-classes="['mm-checkmark-simple']"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div
            v-for="item in displayedSelectOptions"
            :key="item.id"
            class="mm-select-option"
            @click="onItemSelect(item.id)"
          >
            <p class="mm-body-regular-s">
              {{ item.name }}
            </p>
            <SvgIcon
              v-if="selectedId === item.id"
              :src="EIconPath.IndicatorsCheckmarkSimpleSvg"
              :wrapper-classes="['mm-checkmark-simple']"
            />
          </div>
        </div>
      </PerfectScrollbar>
    </div>
    <LoaderButton
      v-show="showButton"
      style="margin-top: 24px"
      :btn-classes="['btn-primary-with-save-background', 'mm-font-500']"
      button-text="Применить"
      @confirm="handleButtonClick"
    />
  </div>
</template>

<script lang="ts" setup>
import RadioGroup from '../RadioGroup.vue';
import { EFilterTypes } from '../../enums/filters.enum';
import { IRadioButton } from '../../models/radioGroup.model';
import { IFilterGroupedSortOption, IFilterSort, IFilterSortOption, TFilterSortOptions } from '../../models/filters.model';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';
import LoaderButton from 'shared/components/LoaderButton.vue';
import { EIconPath } from '../../enums/iconPath.enum';

const props = withDefaults(defineProps<{
  // Объект, представляющий фильтр сортировки
  filter?: IFilterSort;
  // Выбранная фильтрация (если есть фильтр)
  modelValue?: IFilterSortOption;
  // Выбранная опция (из списка опций)
  singleValue?: IFilterSortOption;
  // Список доступных вариантов сортировки
  options?: TFilterSortOptions;
   // Флаг, определяющий, должна ли отображаться кнопка Применить
  showButton?: boolean;
  // Вкл/выкл скролл
  scroll?: boolean;
  // Нужен ли поиск
  needSearch?: boolean;
  // Вкл/выкл радио режим
  radioMode?: boolean;
  // Отключен ли компонент
  disabled?: boolean;
  hideName?: boolean;
  // Группировка значений
  grouped?: boolean;
}>(), {
  hideName: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', filter: IFilterSortOption): void;
  (e: 'update:singleValue', value: IFilterSortOption): void;
}>();

const watchSubscription = new WatchSubscription();
const selectedId = ref();
const searchText = ref('');
const internalFilter = ref<IFilterSort | undefined>();
const selectOptions = ref<TFilterSortOptions>([]);

const displayedRadioSelectOptions = computed<Array<IRadioButton>>(
  () => props.radioMode ? displayedSelectOptions.value?.map((option) => ({ label: option.name, value: option.id })) : [],
);

const displayedSelectOptions = computed<Array<IFilterSortOption>>(() => {
  if (props.grouped) {
    return [];
  }

  if (searchText.value) {
    return selectOptions.value?.filter((option) => option?.name.toLowerCase().includes(searchText.value.toLowerCase()));
  }
  return selectOptions.value;
});

const displayedGroupdSelectOptions = computed<Array<IFilterGroupedSortOption>>(
  () => selectOptions.value,
);

function onItemSelect(id: number): void {
  const foundSelectOption = props.grouped
    ? selectOptions.value
      .flatMap((option) => option.options)
      .find((option) => option.id === id)
    : selectOptions.value?.find((item) => item.id === id);

  if (!foundSelectOption || props.disabled) {
    return;
  }

  const newVal = { ...foundSelectOption, type: EFilterTypes.Sort };
  if (props.filter) {
    emit('update:modelValue', newVal);
  } else {
    emit('update:singleValue', newVal);
  }
  selectedId.value = id;
}

function handleRadioChange(id: number): void {
  selectedId.value = id;
  if (!props.showButton) {
    onItemSelect(selectedId.value);
  }
}

function handleButtonClick(): void {
  onItemSelect(selectedId.value);
}

function setInternalFilter(filter?: IFilterSort): void {
  if (!filter) {
    return;
  }

  internalFilter.value = filter;
  selectOptions.value = internalFilter.value.options;
}

function clearSelectedOption() {
  selectedId.value = null;
  if (props.filter) {
    emit('update:modelValue', null);
  } else {
    emit('update:singleValue', null);
  }
}

function setSelectedIndex(options = selectOptions.value, value = props.modelValue): void {
  if (!props.grouped) {
    selectedId.value = options.find((option) => option.id === value?.id)?.id;
    return;
  }

  selectedId.value = options
    .flatMap((option) => option.options)
    .find((option) => option.id === value?.id)?.id;
}

watchSubscription.add(
  watch(
    () => props.options,
    (newValue) => {
      selectOptions.value = newValue || [];
      clearSelectedOption();
    },
  ),

  watch(
    () => props.filter,
    (newValue) => {
      setInternalFilter(newValue);
      clearSelectedOption();
    },
  ),

  watch(
    () => props.modelValue,
    (newModelValue) => setSelectedIndex(undefined, newModelValue),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  if (props.filter) {
    setInternalFilter(props.filter);
  }

  if (props.options) {
    selectOptions.value = props.options;
  }

  if (props.modelValue) {
    setSelectedIndex(props.filter?.options, props.modelValue);
  }

  if (props.singleValue) {
    setSelectedIndex(props.options, props.singleValue);
  }
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-sort-filter {
  padding: 20px 16px;
  max-width: 264px;

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 16px;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $filter-title-color;
    }

    &.pointer {
      cursor: pointer;
    }
  }

  &--with-search {
    width: 100%;
  }

  &__search {
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    overflow: hidden;

    &--scroll {
      max-height: 348px;
      overflow-y: auto;
    }
  }

  .ps {
    width: 100%;
    height: inherit;
  }

  .mm-select-option {
    margin-bottom: 20px;
    line-height: 20px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mm-body-regular-s {
      margin: 0;
      max-width: calc(100% - 20px);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mm-select-grouped {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &-group {
      &:not(&:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid $dark-gray;
      }

      &__title {
        color: $text-light-green;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      &__options {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }

      &-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;

        &__name {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $text-black;
        }
      }
    }
  }
}
</style>
