<template>
  <div
    ref="wrapperRef"
    class="dropdown-filter-wrapper"
    :class="{ disabled, 'disabled__with-opacity': disabled && !isOpenPopup }"
  >
    <BaseTooltip
      class="filter"
      interactive
      :placement="popperPlacement"
      :show="isShowModeUsed ? isOpenPopup : undefined"
      :offset-skid="offsetX"
      :offset-distance="offsetY"
      :arrow="false"
      @close:popper="onClosePopper()"
      @open:popper="onOpenPopper()"
    >
      <FilterText
        :name="filter.name"
        :filter="modelValue"
        :original-filter="filter"
        :is-open-filter="isOpenPopup"
        :container-class="containerClass"
        :disabled="disabled"
        :get-filter-active-text="getFilterActiveText"
        @reset="onResetFilter"
        @click="onToggleFilter"
      />

      <template #content>
        <div
          v-if="isOpenPopup"
          ref="contentRef"
          class="filter__popup"
          :style="filter.popupStyle"
          :class="filter.popupClass"
        >
          <p
            v-if="isShowNameFilter"
            class="mm-filter-name"
          >
            {{ filter?.description }}
          </p>

          <BaseFilter
            :model-value="internalFilter.filterState"
            show-button
            is-dropdown
            :filter="filter"
            :is-chosen-all="isChosenAll"
            :base-filter-props="baseFilterProps"
            :disabled="disabled"
            :submit-loader="submitLoader"
            :is-filter-available="props.isFilterAvailable"
            @submit="onSubmit()"
            @update:model-value="onUpdateFilterState($event)"
          />
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>

<script lang="ts" setup>
import BaseTooltip from './BaseTooltip.vue';
import BaseFilter from './BaseFilter.vue';
import { IFilter, IFilterItem, IBaseFilterProps } from '../models/filters.model';
import { EFilterTypes } from '../enums/filters.enum';
import { cloneDeep, isEqual } from 'lodash-es';
import FilterText from './FilterText.vue';
import { EPopperPlacement } from '../enums/popperPlacement.enum';
import { useClickOutside } from '../composables/useClickOutside';
import Loader from '../utils/loaderHelper.util';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = withDefaults(defineProps<{
  // Значение фильтра
  value?: string;
  // Объект, представляющий фильтр. Содержит в себе информацию о фильтре
  filter: IFilter;
  // Значение фильтра, обрабатываемое TextFilter
  modelValue: IFilter;
  // Дополнительный класс для обертки
  containerClass?: string;
  // Дополнительные пропсы
  baseFilterProps?: IBaseFilterProps;
  // Флаг, указывающий, выбраны ли все элементы
  isChosenAll?: boolean;
  // Расположение выпадающего фильтра
  popperPlacement?: EPopperPlacement;
  // Вкл/выкл showMode
  isShowModeUsed?: boolean;
  // Открытый/закрытый фильтр
  open?: boolean;
  // Отключен ли фильтр
  disabled?: boolean;
  // Объект, представляющий из себя лоадер при сабмите
  submitLoader?: Loader;
  // Функция для получения текста активного фильтра
  getFilterActiveText?(filter: IFilter, originalFilter: IFilter): string;
  // Вертикальное смещение
  offsetY?: string;
  // Горизонтальное смещение
  offsetX?: string;
  /** Доступен ли фильтр для сабмита */
  isFilterAvailable?: boolean;
}>(), {
  popperPlacement: EPopperPlacement.Bottom,
  isShowModeUsed: true,
  // vue convert boolean to false
  open: undefined,
});

const emit = defineEmits<{
  (e: 'update:modelValue', newValue: IFilter): void;
  (e: 'open'): void;
  (e: 'close'): void;
  (e: 'toggle'): void;
  (e: 'submit'): void;
  (e: 'reset'): void;
}>();

const arrayShowLabel = [EFilterTypes.Bool];
const internalOpen = ref<boolean>(false);
const contentRef = ref<HTMLDivElement | null>(null);
const wrapperRef = ref<HTMLDListElement | null>(null);
const internalFilter = ref<IFilterItem>({
  filter: props.filter,
  filterState: cloneDeep(props.modelValue || props.filter),
});

const watchSubscription = new WatchSubscription();
const isExternalManagement = computed<boolean>(() => props.open != null);
const isOpenPopup = computed<boolean>(() => isExternalManagement.value ? props.open : internalOpen.value);
const isShowNameFilter = computed<EFilterTypes | undefined>(() => arrayShowLabel.find((el) => el === props.filter?.type));

useClickOutside(wrapperRef, () => !props.submitLoader?.value && onClosePopper());

function openPopper(): void {
  if (isExternalManagement.value) {
    return;
  }
  internalOpen.value = true;
}

function closePopper(): void {
  if (isExternalManagement.value) {
    return;
  }
  internalOpen.value = false;
}

function togglePopper(): void {
  if (isExternalManagement.value) {
    return;
  }
  internalOpen.value = !internalOpen.value;
}

function onClosePopper(): void {
  emit('close');
  closePopper();
}

function onOpenPopper(): void {
  if (!props.modelValue) {
    internalFilter.value.filterState = cloneDeep(props.filter);
  }
  emit('open');
  openPopper();
}

function onToggleFilter(): void {
  emit('toggle');
  if (!props.isShowModeUsed) {
    return;
  }
  togglePopper();
}

function onResetFilter(): void {
  resetFilter();
}

function resetFilter(): void {
  internalFilter.value.filterState = cloneDeep(props.filter);
  nextTick(() => {
    emitUpdateModelValue();
    emit('reset');
  });
  props.isShowModeUsed && closePopper();
}

function emitUpdateModelValue(value = internalFilter.value.filterState): void {
  emit('update:modelValue', cloneDeep(value));
}

function onSubmit(): void {
  emitUpdateModelValue();
  emit('submit');
  props.isShowModeUsed && closePopper();
}

function setInternalFilterState(newFilterState?: IFilter): void {
  if (!newFilterState) {
    return;
  }

  if (isEqual(newFilterState, internalFilter.value.filterState)) {
    return;
  }

  internalFilter.value.filterState = cloneDeep(newFilterState);
}

function onUpdateFilterState(filterState: IFilter): void {
  internalFilter.value.filterState = filterState;
}

watchSubscription.add(
  watch(
    () => props.filter,
    (newFilter) => internalFilter.value.filter = newFilter,
  ),

  watch(
    () => props.modelValue,
    (newModelValue) => setInternalFilterState(newModelValue),
    { immediate: true, deep: true },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.dropdown-filter-wrapper {
  display: inline-block;

  &.disabled {
    pointer-events: none;

    &__with-opacity {
      opacity: 0.7;
    }
  }

  :deep(.popper ) {
    background-color: transparent !important;
  }
}

:deep(.filter) {
  .popper {
    background-color: transparent !important;
  }
}

.filter {
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
  position: relative;

  :deep(.mm-sort-filter) {
    max-width: 100%;
    width: 100%;
  }

  :deep(.mm-sort-filter__list) {
    max-height: 420px;
    overflow-y: auto;

    .ps {
      padding-right: 0;

      &__rail-y {
        margin-right: 0;
      }
    }
  }

  &.active {
    background: $filter-bc;

    :deep(svg) {
      transform: rotate(180deg);
    }
  }

  &.filled {
    background: $filter-selected-bc;
    cursor: pointer;
    padding: 8px 16px;

    .filter-icon {
      cursor: pointer;
    }

    :deep(.filter-icon) {
      & > path {
        fill: $filter-text-selected;
      }
    }
  }

  &.filled > &__text {
    color: $filter-text-selected;
    opacity: 0.8;
  }

  &.filled > &__value {
    color: $filter-text-selected;
  }

  &__text {
    color: $filter-text-c;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
  }

  &__value {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &.not-active {
    &:hover > &__text {
      color: $filter-text-hover-c;
    }

    &:hover {
      :deep(.filter-icon) {
        & > path {
          fill: $filter-text-hover-c;
        }
      }
    }
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-4 {
    margin-right: 4px;
  }

  &__popup {
    background: $filter-popup-bc;
    border-radius: 8px;
    box-shadow: 0 2px 18px rgb(0 0 0 / 12%);
    padding: 20px;
    width: 365px;
    margin-top: 4px;
    cursor: auto;

    .mm-filter-name {
      font-size: 16px;
      line-height: 24px;
      color: $text-black;
      font-weight: 500;
    }

    :deep(.mm-range-min),
    :deep(.mm-range-max) {
      width: 153px;

      .mm-input__input {
        width: 153px;
      }

      .mm-input__icon {
        right: 27px;
      }
    }
  }
}
</style>
