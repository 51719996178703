<template>
  <div class="mm-radio-filter">
    <RadioGroup
      v-if="internalFilter.options?.length"
      :model-value="internalFilter.value"
      class="mm-radio-filter__options"
      column
      :buttons="radioGroupButtons"
      :disabled="disabled"
      is-disable-emit-value-by-btn-list-change
      @update:model-value="onSelectValue"
    />
    <div
      v-else
      class="mm-radio-filter__empty"
    >
      Нет данных
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IFilterRadio } from '../../models/filters.model';
import { WatchSubscription } from '../../utils/watchSubscription';
import RadioGroup from '../RadioGroup.vue';
import { IRadioButton } from '../../models/radioGroup.model';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  filter?: IFilterRadio;
  modelValue?: IFilterRadio;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', filter: IFilterRadio): void;
}>();

const watchSubscription = new WatchSubscription();
const internalFilter = ref<IFilterRadio | undefined>();

const radioGroupButtons = computed<Array<IRadioButton>>(
  () => internalFilter.value?.options.map((option) => ({
    value: option.id,
    label: option.name,
    disabled: option.disabled,
  })),
);

function setInternalFilter(newFilter: IFilterRadio): void {
  internalFilter.value = newFilter;
}

function onSelectValue(value: unknown): void {
  internalFilter.value.value = value;
  emit('update:modelValue', internalFilter.value);
}

watchSubscription.add(
  watch(
    () => props.modelValue,
    (newModelValue) => setInternalFilter(newModelValue || props.filter),
  ),
);

onMounted(() => setInternalFilter(props.modelValue || props.filter));

useSSRUnsubscribeWatch(watchSubscription);
</script>
