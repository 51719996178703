<template>
  <form class="mm-range">
    <div class="mm-range-min">
      <TextField
        ref="minTextFieldRef"
        size="small"
        left-label="от"
        icon-disabled
        :disabled="disabled"
        :validation-field="minValueField"
        :model-value="minValue"
        :mask-options="getMaskOptions(minValue)"
        @focus-change="(isFocused) => onFieldFocusChanged(isFocused, 'minValue')"
        @click-clear-button="onClearValue('minValue')"
        @update:model-value="onUpdateValue(String($event), 'minValue')"
        @enter="onPressEnter()"
      />
    </div>

    <div class="mm-range-max">
      <TextField
        ref="maxTextFieldRef"
        size="small"
        left-label="до"
        icon-disabled
        :disabled="disabled"
        :validation-field="maxValueField"
        :model-value="maxValue"
        :mask-options="getMaskOptions(maxValue)"
        @focus-change="(isFocused) => onFieldFocusChanged(isFocused, 'maxValue')"
        @click-clear-button="onClearValue('maxValue')"
        @update:model-value="onUpdateValue(String($event), 'maxValue')"
        @enter="onPressEnter()"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import TextField from '../../components/TextField.vue';
import { cloneDeep } from 'lodash-es';
import { useField } from 'vee-validate';
import { IFilterRange } from '../../models/filters.model';
import IMask from 'imask';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  filter: IFilterRange;
  modelValue: IFilterRange;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', newValue: IFilterRange | undefined): void;
  (e: 'enter'): void;
}>();

const minValue = ref<string>(props.modelValue?.minValue ? props.modelValue.minValue?.toString() : props.filter?.minValue?.toString());
const maxValue = ref<string>(props.modelValue?.maxValue ? props.modelValue.maxValue?.toString() : props.filter?.maxValue?.toString());

const minValueField = useField<string>('minValue');
const maxValueField = useField<string>('maxValue');

const minTextFieldRef = ref<InstanceType<typeof TextField>>();
const maxTextFieldRef = ref<InstanceType<typeof TextField>>();

const watchSubscription = new WatchSubscription();
let disableFocusChangeAction = false;

const maskOptions: IMask.AnyMaskedOptions = {
  mask: IMask.MaskedNumber,
  scale: 2,
  radix: '.',
  mapToRadix: ['.', ','],
  thousandsSeparator: ' ',
  max: 1000000000,
  lazy: false,
};

function onUpdateValue(value: string, field: 'minValue' | 'maxValue') {
  if (field === 'minValue') {
    if (+value === props.filter?.minValue && props.modelValue === undefined) {
      return;
    }
    minValue.value = value;
  }

  if (field === 'maxValue') {
    if (+value === props.filter?.maxValue && props.modelValue === undefined) {
      return;
    }
    maxValue.value = value;
  }
};

function onClearValue(field: 'minValue' | 'maxValue') {
  disableFocusChangeAction = true;
  const valueRef = field === 'minValue' ? minValue : maxValue;
  const fieldRef = field === 'minValue' ? minTextFieldRef : maxTextFieldRef;
  setTimeout(() => {
    valueRef.value = '';
    fieldRef.value.mask.unmaskedValue = '';
    fieldRef.value.inputRef?.focus();
  });
}

function calculateNewValue(field: 'minValue' | 'maxValue') {
  if (field === 'maxValue') {
    return Math.max(Math.min(+maxValue.value, props.filter?.maxValue), +minValue.value)?.toString();
  }
  return Math.min(Math.max(+minValue.value, props.filter?.minValue), +maxValue.value)?.toString();
}

function onFieldFocusChanged(isFocused: boolean, field: 'minValue' | 'maxValue') {
  if (isFocused || disableFocusChangeAction) {
    disableFocusChangeAction = false;
    return;
  }

  if (field === 'maxValue') {
    maxValue.value = calculateNewValue('maxValue');
    if (maxTextFieldRef?.value?.mask?.unmaskedValue) {
      maxTextFieldRef.value.mask.unmaskedValue = maxValue.value;
    }
  } else {
    minValue.value = calculateNewValue('minValue');
    if (minTextFieldRef?.value?.mask?.unmaskedValue) {
      minTextFieldRef.value.mask.unmaskedValue = minValue.value;
    }
  }
  emitNewValue();
};

function emitNewValue() {
  if (!(minValue.value + maxValue.value)) {
    emits('update:modelValue', undefined);
    return;
  }
  const filterClone: IFilterRange = cloneDeep(toRaw(props.filter));
  filterClone.minValue = Number(minValue.value);
  filterClone.maxValue = Number(maxValue.value);
  emits('update:modelValue', filterClone);
}

function setValues(modelValue: IFilterRange | undefined): void {
  minValue.value = modelValue?.minValue?.toString() || props.filter?.minValue?.toString();
  maxValue.value = modelValue?.maxValue?.toString() || props.filter?.maxValue?.toString();
}

function getMaskOptions(value: string): IMask.AnyMaskedOptions {
  return !parseFloat(value) ? {
    mask: /^[\d.]+$/gm,
  } : { ...maskOptions };
}

async function onPressEnter(): Promise<void> {
  onFieldFocusChanged(false, 'minValue');
  onFieldFocusChanged(false, 'maxValue');
  await nextTick();
  emits('enter');
}

watchSubscription.add(
  watch(
    () => props.modelValue,
    (newModelValue) => setValues(newModelValue),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
.mm-range {
  display: flex;
  align-items: center;
  width: 365px;

  :deep(.mm-input--is-small) {
    .mm-input__input {
      padding-top: 11px !important;
    }
  }

  &-min {
    margin-right: 20px;
  }

  &--is-invalid {
    margin-bottom: 0 !important;
  }

  .mm-input {
    width: 174px;
  }

  :deep(.mm-input__icon) {
    right: 12px;
  }
}
</style>
