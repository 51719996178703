<template>
  <div class="m-sort-filter">
    <div v-if="needSearch">
      <TextField
        v-model="searchText"
        :disabled="disabled"
        icon-path="navigation/search"
        placeholder="Поиск"
        size="small"
      />
    </div>

    <div
      v-if="filteredOptions.length"
      class="m-sort-filter__options"
    >
      <PerfectScrollbar
        watch-options
        :options="{ wheelPropagation: true, scrollYMarginOffset: 5 }"
      >
        <div>
          <template
            v-for="option in filteredOptions"
            :key="option.id"
          >
            <div
              v-if="option.name"
              class="m-sort-filter__option m-sort-option"
              @click="onItemSelect(option.id)"
            >
              <div
                class="m-sort-option__marker"
                :class="{
                  'm-sort-option__marker--selected': selectedId === option.id,
                }"
              />

              <div class="m-sort-option__title">
                {{ option.name }} <span class="m-sort-option__count">{{ option.count }}</span>
              </div>
            </div>
          </template>
        </div>
      </PerfectScrollbar>
    </div>
    <span
      v-else
      class="m-sort-filter__no-options"
    >
      Нет данных
    </span>
  </div>
</template>

<script lang="ts" setup>
import { EFilterTypes } from '../../enums/filters.enum';
import { IFilterRadio, IFilterRadioOption } from '../../models/filters.model';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  filter?: IFilterRadio;
  modelValue?: IFilterRadio | string | number | boolean;
  singleValue?: IFilterRadioOption;
  needSearch?: boolean,
  options?: Array<IFilterRadioOption>;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', filter: IFilterRadio);
  (e: 'update:singleValue', value: string | number | boolean);
}>();

const watchSubscription = new WatchSubscription();
const selectedId = ref();
const internalFilter = ref<IFilterRadio | undefined>();
const selectOptions = ref<Array<IFilterRadioOption>>([]);
const searchText = ref<string>('');

const filteredOptions = computed<Array<IFilterRadioOption>>(() => {
  return searchText.value
    ? selectOptions.value.filter((option) => option.name && option.name.toLowerCase().includes(searchText.value.toLowerCase()))
    : selectOptions.value.filter((option) => option.name);
});

function onItemSelect(id: number): void {
  const foundSelectOption = selectOptions.value?.find((item) => item.id === id);
  if (!foundSelectOption || props.disabled) {
    return;
  }

  const newVal = { ...foundSelectOption, type: EFilterTypes.MSort };
  if (props.filter) {
    emit('update:modelValue', newVal);
  } else {
    emit('update:singleValue', newVal);
  }
  selectedId.value = id;
}

function setInternalFilter(filter: IFilterRadio) {
  internalFilter.value = filter;
  selectOptions.value = internalFilter.value.options;
}

function clearSelectedOption() {
  selectedId.value = null;
  if (props.filter) {
    emit('update:modelValue', null);
  } else {
    emit('update:singleValue', null);
  }
}

function setSelectedIndex(options = selectOptions.value, value = props.modelValue): void {
  selectedId.value = options.find((option) => option.id === value?.id)?.id;
}

watchSubscription.add(
  watch(props.options, (newValue) => {
    selectOptions.value = newValue;
    clearSelectedOption();
  }),

  watch(props.filter, (newValue) => {
    setInternalFilter(newValue);
    clearSelectedOption();
  }),

  watch(
    () => props.modelValue,
    (newModelValue) => setSelectedIndex(undefined, newModelValue),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  if (props.filter) {
    setInternalFilter(props.filter);
  }
  if (props.filter?.selectedValue) {
    setSelectedIndex(props.filter.options || [], props.filter.selectedValue);
  }
  if (props.options) {
    selectOptions.value = props.options;
  }
  if (props.modelValue) {
    setSelectedIndex(props.filter?.options, props.modelValue);
  }
  if (props.singleValue) {
    setSelectedIndex(props.options, props.singleValue);
  }
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.m-sort-filter {
  width: 100%;
}

.m-sort-filter__options {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  max-height: 365px;
}

.m-sort-filter__options .ps {
  width: 100%;
  height: inherit;
}

.m-sort-option {
  display: inline-flex;
  gap: 12px;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;

  &:has(.m-sort-option__marker--selected) {
    pointer-events: none;
  }

  & + & {
    margin-top: 12px;
  }
}

.m-sort-option__marker {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: $light-gray;
  border: 1px solid $radio-default-marker;
  border-radius: 50%;

  &--selected {
    background-color: $radio-checked-contrast-dot;
    border-color: $radio-checked-bc;
    border-width: 8px;
  }
}

.m-sort-option__title {
  color: $text-black;

  :not(&--selected):hover > & {
    color: $filter-count-color;
  }
}

.m-sort-option__count {
  color: $filter-count-color;
  margin-left: 12px;
}

.m-sort-filter__no-options {
  color: $filter-no-options-message;
}
</style>
